<template>
  <div class="homeView">
    <div>
      <img :src="header_url" alt="" style="width: 100%" v-if="header_url">

    </div>
    <header_tab :ArticleSortList="ArticleSortList" v-if="ArticleSortList.length>0"></header_tab>
    <div class="main container">
      <div class="swiper-container">
        <div class="swiper-wrapper" style="width: 100%;"  ref="mySwiper">
          <div class="swiper-slide" v-for="(item,index) in WebConfig" :key="index"><a :href="item.url"><img :src="item.img" alt=""  style="width:100%"/></a></div>
<!--          <div class="swiper-slide"><img src="../../assets/images/banner2.png" alt=""  style="width:100%"/></div>-->
        </div>
        <div class="swiper-button-prev"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
        <div class="swiper-button-next"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
      </div>
      <div class="new_list">
        <newList :newsData="newsData1"></newList>
        <newList :newsData="newsData2"></newList>

        <newList :newsData="newsData3"></newList>
        <newList :newsData="newsData4"></newList>
        <newList :newsData="newsData5"></newList>
        <newList :newsData="newsData6"></newList>
      </div>
      <div class="code_box">
          <div>
            <img src="../../assets/images/1.jpg" alt="">
            <p>浙江省抗癌协会微信公众号</p>
          </div>
        <div>
          <img src="../../assets/images/2.png" alt="">
          <p>浙江省癌症康复会微信公众号</p>
        </div>
        <div>
          <img src="../../assets/images/3.png" alt="">
          <p>浙江省抗癌协会微信视频号</p>
        </div>
        <div>
          <img src="../../assets/images/4.png" alt="" style="margin-top: 8px;padding-bottom: 4px;">
          <p>浙江省抗癌协会抖音号</p>
        </div>
      </div>
      <div class="friend_link">
          <div class="frend_title">
            友情链接
          </div>
          <ul class="">
            <li><a href="http://www.caca.org.cn/" target="_blank">中国抗癌协会</a></li>
            <li><a href="https://www.zchospital.com/" target="_blank">浙江省肿瘤医院</a></li>
          </ul>
      </div>
    </div>
    <footer_tab></footer_tab>
  </div>
</template>

<script>
import api from '@/service/api.js'
import header_tab from '@/components/headerTab'
import footer_tab from '@/components/footerTab'
import newList from '@/components/newList'
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

export default {
  name: "HomeView",
  components: {
    header_tab,footer_tab,newList
  },
  data(){
    return {
      WebConfig:{},
      ArticleSortList: {},
      header_url:'',
      newsData1:[{
        id:417996628107269,
        name:"协会动态"
      }],
      newsData2:[{
        id:417996778446853,
        name:"通知公告"
      }],
      newsData3:[{
        id:417997011099653,
        name:"学术科研动态"
      },{
        id:417996569923589,
        name:"专业委员会"
      }],
      newsData4:[{
        id:417997110861829,
        name:"科普宣传"
      },{
        id:418002306588677,
        name:"期刊杂志"
      }],
      newsData5:[{
        id:417996718776325,
        name:"各地康复会活动"
      },{
        id:417996594044933,
        name:"康复组织"
      }],
      newsData6:[{
        id:418002265436165,
        name:"癌症康复"
      },{
        id:418318223314949,
        name:"常见恶性肿瘤"
      }],
    }
  },
  mounted() {
    api.client.web.getArticleSortList({}, '?tenantId=417991687598085').then((r) => {
      console.log(r)
      this.ArticleSortList=r.data.data

    })
    api.client.getAppSetting({}, '?code=kaxh00001').then((r) => {
     this.header_url=JSON.parse(r.data.data).url
      this.WebConfig=JSON.parse(r.data.data).imgList
      console.log( JSON.parse(r.data.data),'JSON.parse(r.data.data)')

      setTimeout(function () {
        new Swiper(".swiper-container", {
          slidesPerView: 1,
          spaceBetween: 30,
          centeredSlides: true,
          autoplay:true,
          initialSlide: 2,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
      },300)
    })
    // api.client.web.getWebConfig({}, '?id=417991687598085').then((r) => {
    //   console.log(r,'111')
    //
    //
    // })


  }
}
</script>

<style scoped>

  .code_box{
    background: #F6F6F6;
    display: flex;
    padding: 20px 0;
    margin: 20px 0;
  }
  .code_box>div{
    text-align: center;
    width: 25%;
    font-weight: 800;
  }
  .code_box>div img{
    text-align: center;
    width: 120px;
  }
  .frend_title{
    line-height: 50px;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 800;
    color: #014099;
    border-bottom: 1px solid #F6F6F6;
  }
  a{
    color:#333;
    text-decoration: none;

  }
  ul li{
    margin-right: 50px;
  }
  ul li::marker{
    color: #014099;
  }
  ul{
    display: flex;
    padding-bottom: 30px;
  }
</style>