<template>
    <div class="header_box">
      <div class="header_top container">
        <img src="../assets/images/logo.png" alt="" style="width: 100px;vertical-align: middle;margin-right: 30px;">
        <img src="../assets/images/font.png" alt="" style="width:250px;position: relative;top: 10px">
        <div class="header_right">
          <input type="text" placeholder="请输入搜索内容" @keyup.enter='submit' v-model="name">
          <router-link :to="{name: 'ArticleDetail', params: {id:418396644089861}, replace: true}"><span style="margin-left: 30px;color:#014099;font-size: 18px;font-weight: 800; ">申请入会</span></router-link>
        </div>
      </div>
      <div class="nav">
        <div class="container">
          <nav ><router-link to="/">首页</router-link></nav>
          <nav v-for="(item,index) in ArticleSortList"   :key="index"><span><router-link :to="{name:'ArticleDetail2', params: {id: item.children[0].id}, replace: true}" target="_blank" v-if="item.name=='关于协会'">{{item.name}}</router-link><router-link :to="{name: item.sortType==0?'ArticleList':'ArticleDetail2', params: {id: item.id}, replace: true}" target="_blank" v-if="item.name!='关于协会'">{{item.name}}</router-link></span>
            <ul>
              <li v-for="(item2,index2) in item.children" :key="index2"><router-link :to="{name: item2.sortType==0?'ArticleList':'ArticleDetail2', params: {id: item2.id}, replace: true}" target="_blank">{{item2.name}}</router-link></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
</template>

<script>


export default {
  name: "headerTab",
  props: {
    ArticleSortList: {}
  },
  data(){
    return {
     name:''
    }
  },
  methods: {
    submit() {
      this.$router.push({
        name: "Search",
        params: {name:this.name}
      });
    }
  },
  mounted() {
    console.log(this.ArticleSortList) // 访问传递过来的值

  }
}
</script>

<style scoped>
  .header_top{
    padding:15px 0
  }
  a{
    color: #fff;
    text-decoration: none;
  }
  .header_right{
    float: right;
    line-height: 95px;

  }
  input{
      padding: 8px 10px;
      padding-right: 40px;
      color: #333;
      width: 280px;
      background: url("../assets/images/search.png") 100% 100% no-repeat;
      background-size:  auto 50%;
      background-position: calc(100% - 10px) ;
      outline: 0;
      border: 1px solid #ccc;
  }
  .nav{
    line-height: 50px;
    height: 50px;
    background:linear-gradient(#0144aa,#00317a) ;
  }
  .nav .container{
    color: #fff;
    font-size: 18px;
    font-weight: 800;
    display: flex;
  }
  .nav .container nav{
      padding: 0 12.8px;
    min-width:  95px;
    text-align: center;

    position: relative;
  }
  nav{
    position: relative;
    cursor: pointer;
  }
  ul a{
    color: #333;
  }
  nav>ul{

    min-width:  200px;
    display: none;
    padding: 0 10px;
    background: #ffffff;
    position: absolute;
    color: #333;
    margin-left: 0px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-inline-start: 10px;
    text-align: left;
    left: -35px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    z-index: 10000000000;
  }
  nav:hover>ul{
    display: block;
  }
  nav>ul li{
    list-style: none;
    font-size: 15px;
    font-weight: 400;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
</style>