
import axios from 'axios'

function GUID() {
    this.date = new Date();   /* 判断是否初始化过，如果初始化过以下代码，则以下代码将不再执行，实际中只执行一次 */
    if (typeof this.newGUID != 'function') {   /* 生成GUID码 */
        GUID.prototype.newGUID = function () {
            this.date = new Date(); var guidStr = '';
            var sexadecimalDate = this.hexadecimal(this.getGUIDDate(), 16);
            var sexadecimalTime = this.hexadecimal(this.getGUIDTime(), 16);
            for (var i = 0; i < 9; i++) {
                guidStr += Math.floor(Math.random() * 16).toString(16);
            }
            guidStr += sexadecimalDate;
            guidStr += sexadecimalTime;
            while (guidStr.length < 32) {
                guidStr += Math.floor(Math.random() * 16).toString(16);
            }
            return this.formatGUID(guidStr);
        }
        /* * 功能：获取当前日期的GUID格式，即8位数的日期：19700101 * 返回值：返回GUID日期格式的字条串 */
        GUID.prototype.getGUIDDate = function () {
            return this.date.getFullYear() + this.addZero(this.date.getMonth() + 1) + this.addZero(this.date.getDay());
        }
        /* * 功能：获取当前时间的GUID格式，即8位数的时间，包括毫秒，毫秒为2位数：12300933 * 返回值：返回GUID日期格式的字条串 */
        GUID.prototype.getGUIDTime = function () {
            return this.addZero(this.date.getHours()) + this.addZero(this.date.getMinutes()) + this.addZero(this.date.getSeconds()) + this.addZero(parseInt(this.date.getMilliseconds() / 10));
        }
        /* * 功能: 为一位数的正整数前面添加0，如果是可以转成非NaN数字的字符串也可以实现 * 参数: 参数表示准备再前面添加0的数字或可以转换成数字的字符串 * 返回值: 如果符合条件，返回添加0后的字条串类型，否则返回自身的字符串 */
        GUID.prototype.addZero = function (num) {
            if (Number(num).toString() != 'NaN' && num >= 0 && num < 10) {
                return '0' + Math.floor(num);
            } else {
                return num.toString();
            }
        }
        /*  * 功能：将y进制的数值，转换为x进制的数值 * 参数：第1个参数表示欲转换的数值；第2个参数表示欲转换的进制；第3个参数可选，表示当前的进制数，如不写则为10 * 返回值：返回转换后的字符串 */GUID.prototype.hexadecimal = function (num, x, y) {
            if (y != undefined) { return parseInt(num.toString(), y).toString(x); }
            else { return parseInt(num.toString()).toString(x); }
        }
        /* * 功能：格式化32位的字符串为GUID模式的字符串 * 参数：第1个参数表示32位的字符串 * 返回值：标准GUID格式的字符串 */
        GUID.prototype.formatGUID = function (guidStr) {
            var str1 = guidStr.slice(0, 8) + '-', str2 = guidStr.slice(8, 12) + '-', str3 = guidStr.slice(12, 16) + '-', str4 = guidStr.slice(16, 20) + '-', str5 = guidStr.slice(20);
            return str1 + str2 + str3 + str4 + str5;
        }
    }
}


const Tool = {

    isWeiXin() {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    },

    isMobilePhone() {
        var sUserAgent = navigator.userAgent.toLowerCase();
        var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
        var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
        var bIsMidp = sUserAgent.match(/midp/i) == "midp";
        var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
        var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
        var bIsAndroid = sUserAgent.match(/android/i) == "android";
        var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
        var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
        if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
            return true;
        } else {
            return false;
        }
    },

    guid() {
        return new GUID();
    },

    getCookie(c_name) {
        if (document.cookie.length > 0) {
            var name = c_name + "=";
            var ca = document.cookie.split(';');
            for(var i=0; i<ca.length; i++)
            {
                var c = ca[i].trim();
                if (c.indexOf(name)==0) return c.substring(name.length,c.length);
            }
            return "";
        }
        return ""
    },
    setCookie(c_name, value, expiredays) {

        if(!expiredays){
            expiredays=30
        }
        var exdate = new Date()
        exdate.setDate(exdate.getDate() + expiredays)
        document.cookie = c_name + "=" + value +
            ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString()) + "; path=/"
    },
    getUrlParam(name) {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return decodeURIComponent(r[2]); return null;
    },

    trimToken() {
        let url = window.location.href;
        var token = this.getUrlParam('token')
        url = url.replace('token=' + token, '')

        window.location.replace(url);
    },

    dateFormat(fmt, date) {
        var o = {
            "M+": date.getMonth() + 1, //月份
            "d+": date.getDate(), //日
            "h+": date.getHours(), //小时
            "m+": date.getMinutes(), //分
            "s+": date.getSeconds(), //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度
            "S": date.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;

    },


    dateRange(startTime, endTime) {
        //var startTime = new Date(startTime);

        startTime = new Date(startTime.replace(/-/g, '/'));

        if (endTime) {
            endTime = new Date(endTime)
        }

        var Day = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
        var year = new Date().getFullYear();
        var formatTime = "";
        //判断开始时间是否是今年
        if (startTime.getFullYear() == year) {
            formatTime = this.dateFormat('M月dd日', startTime);//ToString("M月dd日");
        }
        else {
            formatTime = this.dateFormat("yyyy年M月dd日", startTime);
        }
        formatTime += " " + Day[startTime.getDay()];
        formatTime += " " + this.dateFormat("hh:mm", startTime);
        if (endTime) {
            formatTime += " - ";
            if (this.dateFormat("yyyyMMdd", startTime) == this.dateFormat("yyyyMMdd", endTime)) {
                formatTime += this.dateFormat("hh:mm", endTime);
            }
            else if (endTime.getFullYear() == year) {
                formatTime += this.dateFormat("M月dd日 hh:mm", endTime);
            }
            else {
                formatTime += this.dateFormat("yyyy年M月dd日 hh:mm", endTime);
            }
        }
        return formatTime;
    },


    trimZero(num) {
        var numNew = num.toString();
        var dotPo = numNew.indexOf('.');
        if (dotPo < 0) {
            return num;
        }

        var mins = numNew.split('.')[1].substr(0, 2);

        return (numNew.split('.')[0] + '.' + mins).replace('.00', '');

    },

    // b>a
    calcTimeSpan(b, a) {
        var start = a;
        var end = b;

        if (!(a instanceof Date)) {
            start = a.replace('T', ' ').replace(/-/g, '/').substr(0, 19);
            start = new Date(a);
        }

        if (!(b instanceof Date)) {
            end = b.replace('T', ' ').replace(/-/g, '/').substr(0, 19);
            end = new Date(b);
        }

        return Math.abs((end - start) / 1000);

    },


    timestampFormat(timestamp) {
        if (!(timestamp instanceof Date)) {
            timestamp = new Date(timestamp.replace('T', ' ').replace(/-/g, '/').substr(0, 19));
        }

        timestamp = timestamp.getTime();


        function zeroize(num) {
            return (String(num).length == 1 ? '0' : '') + num;
        }

        var curTimestamp = parseInt(new Date().getTime() / 1000); //当前时间戳
        var timestampDiff = curTimestamp - timestamp; // 参数时间戳与当前时间戳相差秒数

        var curDate = new Date(curTimestamp * 1000); // 当前时间日期对象
        var tmDate = new Date(timestamp * 1000);  // 参数时间戳转换成的日期对象

        var Y = tmDate.getFullYear(), m = tmDate.getMonth() + 1, d = tmDate.getDate();
        var H = tmDate.getHours(), i = tmDate.getMinutes();//, s = tmDate.getSeconds();

        if (timestampDiff < 60) { // 一分钟以内
            return "刚刚";
        } else if (timestampDiff < 3600) { // 一小时前之内
            return Math.floor(timestampDiff / 60) + "分钟前";
        } else if (curDate.getFullYear() == Y && curDate.getMonth() + 1 == m && curDate.getDate() == d) {
            return '今天' + zeroize(H) + ':' + zeroize(i);
        } else {
            var newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
            if (newDate.getFullYear() == Y && newDate.getMonth() + 1 == m && newDate.getDate() == d) {
                return '昨天' + zeroize(H) + ':' + zeroize(i);
            } else if (curDate.getFullYear() == Y) {
                return zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
            } else {
                return Y + '年' + zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
            }
        }
    }
}

export const MATH = {
    add(arg1, arg2) {
        var r1, r2, m;
        try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
        try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
        m = Math.pow(10, Math.max(r1, r2));
        return (arg1 * m + arg2 * m) / m;
    },

    //减 
    sub(arg1, arg2) {
        var r1, r2, m, n;
        try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
        try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
        m = Math.pow(10, Math.max(r1, r2));
        //动态控制精度长度 
        n = (r1 >= r2) ? r1 : r2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },

    //乘 
    mul(arg1, arg2) {
        var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
        try { m += s1.split(".")[1].length } catch (e) { console.warn(1); }
        try { m += s2.split(".")[1].length } catch (e) { console.warn(1); }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    },


    //除 
    div(arg1, arg2) {
        var t1 = 0, t2 = 0, r1, r2;
        try { t1 = arg1.toString().split(".")[1].length } catch (e) { console.warn(1); }
        try { t2 = arg2.toString().split(".")[1].length } catch (e) { console.warn(1); }

        r1 = Number(arg1.toString().replace(".", ""));

        r2 = Number(arg2.toString().replace(".", ""));
        return (r1 / r2) * Math.pow(10, t2 - t1);
    }
}


export const Weixin = {
    isintall: false,
    init(shareData) {
        if (this.isintall) {
            //console.log('已经加载weixin sdk');
            this.getapi(shareData);

            return;
        } else {
            //console.log('未加载weixin sdk');
            this.isintall = true;

            var secScript = document.createElement("script");
            secScript.setAttribute("type", "text/javascript");
            secScript.setAttribute("src", "https://res.wx.qq.com/open/js/jweixin-1.0.0.js");
            document.body.insertBefore(secScript, document.body.lastChild);


            secScript.onload = secScript.onreadystatechange = () => {
                if (!this.readyState || this.readyState === "loaded" || this.readyState === "complete") {
                    this.getapi(shareData);
                    secScript.onload = secScript.onreadystatechange = null;
                }
            };
        }
    },

    getapi(shareData) {

        axios({

            method: 'post',
            url: '//m.zhundao.net/OAuth/WxShare?url=' + encodeURIComponent(window.location.href),
            //headers: {
            //    'Content-Type': 'application/json;charset=UTF-8'
            //},
            //data: JSON.stringify({}),
        }).then(r => {
            var data = r.data;

            window.wx.config({
                debug: false,
                appId: data.AppId,
                timestamp: data.Timestamp,
                nonceStr: data.NonceStr,
                signature: data.Signature,
                jsApiList: [
                    'updateAppMessageShareData',
                    'onMenuShareAppMessage',
                    'onMenuShareTimeline'
                ]
            });
        }).then(() => {

            setTimeout(function () {

                window.wx.ready(function () {
                    window.wx.showOptionMenu();


                    var url = window.location.href;



                    var pattern = /&scene=\w*&clicktime=\w*&enterid=\w*/;

 

                    window.wx.onMenuShareAppMessage({
                        title: shareData.title,
                        desc: shareData.desc,
                        imgUrl: shareData.img,
                        link: url.replace(/\?from=(groupmessage|singlemessage|timeline)(\S*)#/, '#').replace(pattern, ''),                       
                        success: function () {

                            console.log('success')
                        }
                    });
                    window.wx.onMenuShareTimeline({
                        title: shareData.title,
                        desc: shareData.desc,
                        imgUrl: shareData.img,
                        link: url.replace(/\?from=(groupmessage|singlemessage|timeline)(\S*)#/, '#').replace(pattern, ''),
                        success: function () {
                            // 用户确认分享后执行的回调函数
                        },
                        cancel: function () {
                            // 用户取消分享后执行的回调函数
                        }
                    });
                });


            }, 0)


        })




    }

}


export default Tool;
