<template>
  <div>
    <header_tab :ArticleSortList="ArticleSortList"></header_tab>

    <div class="container">

      <div class="newList_box">
        <div  class="newList_box_main">
          <div class="newList_li" v-for="(item,index) in ArticleList" :key="index" @click="toDetail(item)">
            <span>{{item.title}}</span>
            <div class="time">{{item.publishTime.substring(5, 10)}}</div>
          </div>
        </div>
      </div>
      <el-pagination
          style="text-align: center;padding: 40px 0"
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="15"
          :total="total">
      </el-pagination>
    </div>
    <footer_tab></footer_tab>
  </div>
</template>

<script>
import api from '@/service/api.js'
import header_tab from "@/components/headerTab";
import footer_tab from "@/components/footerTab";
export default {
  data(){
    return {
      ArticleList:[],
      ArticleSortList:[],
      total:0,
      PageIndex:1,
    }
  },
  components: {
    header_tab,footer_tab
  },
  name:'newsList',
  methods:{
    change(e){
      this.news_index=e
      this.loadList()
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.PageIndex=val
      this.loadList()
    },
    toDetail(i){
      this.$router.push({
        name: "ArticleDetail",
        params: {id:i.id}
      });
    },
    loadList(){
      api.client.web.getArticleList({}, '?PageIndex='+this.PageIndex+'&PageSize=15&tenantId=417991687598085&search='+this.$route.params.name).then((r) => {
        console.log(r)
        this.ArticleList=r.data.data.rows
        this.total=r.data.data.totalCount
      })
    },
  },
  mounted() {
    api.client.web.getArticleSortList({}, '?tenantId=417991687598085').then((r) => {
      console.log(r)
      this.ArticleSortList=r.data.data
    })
    this.loadList()

  }
}
</script>

<style scoped>
.newListPage{
  vertical-align: top;
  width: calc(100% - 21px);
  box-sizing: border-box;
  margin-right: 20px;
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 30px;

}
.newList_box{
  padding:30px 0;
  min-height: 570px;
}
.newList_box_header{
  background: #F7F7F7;
  line-height: 50px;
  height: 50px;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.newList_box_header>div{
  cursor: pointer ;
}
.List_nav{
  display: inline-block;
  padding: 0px 10px;
  font-size: 18px;
  font-weight:800;
  color: #555;

}
.time{
  display: inline-block;
}
.active{
  color: #0144aa;
  line-height: 46px;
  border-bottom: 2px solid #0144aa;
}
.newList_li > span{
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  width: calc(100% - 130px);
  display: inline-block;
  margin-left: 30px;
  margin-right: 30px;
  vertical-align: top;

}
.newList_li{
  font-size: 15px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #ddd;
  color: #666;
  cursor: pointer;
}
.newList_li:before{
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  background: #0144aa;
  position: relative;
  right: -15px;
  top:-2.5px
}
</style>