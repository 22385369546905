<template>
    <div class="newListPage">
        <div class="newList_box" >
            <div class="newList_box_header " >
              <div v-for="(item,index) in newsData" :key="index" :class="index==news_index?'List_nav active':'List_nav'" @click="change(index)">{{item.name}}</div>
              <div class="more" style="float: right;margin-right: 5px;color: #999" @click="toList()">更多>></div>
            </div>
            <div class="newList_box_main list" ref="list" v-if="this.newsData[this.news_index].id!=418002306588677">
              <div class="newList_li" v-for="(item,index) in ArticleList" :key="index" @click="toDetail(item)" style="cursor: pointer">
                <span>{{item.title}}</span>
                <div class="time" v-if="showtime">{{item.publishTime.substring(5, 10)}}</div>
              </div>
            </div>
          <div class="newList_box_main list" ref="list" v-if="this.newsData[this.news_index].id==418002306588677">
            <div class="newList_li2" style="cursor: pointer;vertical-align:top;width:49.8%;display: inline-block;text-align: center" @click="toList2('418002363654149')">
              <img src="https://res.zhundaoyun.com/szhpt/417991687598085/417991687774213/423669005606917.png" alt="" style="width: 80%">
              <p>《浙江抗癌信息》</p>
            </div>
            <div class="newList_li2" style="cursor: pointer;vertical-align:top;width:49.8%;display: inline-block;text-align: center" @click="toList2('418002388783109')">
              <img src="https://res.zhundaoyun.com/szhpt/417991687598085/417991687774213/423625975197701.png" alt="" style="width: 80%">
              <p>《康复与保健》</p>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import api from '@/service/api.js'
/* eslint-disable */
export default {
  name: "newList",
  props: {
    newsData: {},

  },
  data(){
    return {
      news_index: 0,
      ArticleList:[],
      showtime:true
    }
  },
  methods:{
    change(e){
      this.news_index=e
      this.loadList()
    },
    toList(){
      window.open(`/ArticleList/${this.newsData[this.news_index].id}`, '_blank')

    },
    toList2(e){
      window.open(`/ArticleList/${e}`, '_blank')

    },
    toDetail(i){
      window.open(`/ArticleDetail/${i.id}`, '_blank')

    },
    handleMouseEnter(){
      clearInterval(this.timer)
    },

    handleMouseLeave() {
      if(this.newsData[this.news_index].id==417996569923589||this.newsData[this.news_index].id==417996594044933){
        this.listAnm()
      }

    },
    initList() { //初始化列表

      this.listAnm()
    },
    listAnm(){
      const list = this.$refs.list;
      const firstItem = list.children[0];
      // const lastItem = list.children[list.children.length - 1];
      const itemHeight = firstItem.offsetHeight;
      //list.insertBefore(lastItem.cloneNode(true), list.children[0]); //将最后一项复制到第一项之前，以便循环滚动
      list.style.transition = 'transform 3s linear';
      list.style.transform = `translateY(-${itemHeight}px)`; //向上滚动一个item高度的距离
      setTimeout(() => {
        const firstItem = list.children[0];
        list.style.transition = 'transform 0s linear';
        list.appendChild(firstItem); //将第一项移动到最后一项之后

        list.style.transform = 'translateY(0)'; //重置transform
      }, 2950);
      this.timer = setInterval(() => {
        list.style.transition = 'transform 3s linear';
        list.style.transform = `translateY(-${itemHeight}px)`; //向上滚动一个item高度的距离
        setTimeout(() => {
          const firstItem = list.children[0];
          list.style.transition = 'transform 0s linear';
          list.appendChild(firstItem); //将第一项移动到最后一项之后

          list.style.transform = 'translateY(0)'; //重置transform
        }, 2950);
      }, 3000);
    },
    loadList(){
      if((this.newsData[1]&&this.newsData[1].id==417996594044933)){
        this.news_index=1
      }
      if(this.newsData[this.news_index].id==417996569923589||this.newsData[this.news_index].id==417996594044933){
        this.showtime=false
        api.client.web.getArticleList({}, '?PageIndex=1&PageSize=10&parentId='+this.newsData[this.news_index].id).then((r) => {
          console.log(r)
          this.ArticleList=r.data.data.rows
          setTimeout(() => {
            this.initList()
          }, 500);
          if(this.timer){
            clearTimeout(this.timer);
          }
        })
      }else{
        this.showtime=true
        api.client.web.getArticleList({}, '?PageIndex=1&PageSize=5&parentId='+this.newsData[this.news_index].id).then((r) => {
          console.log(r)
          this.ArticleList=r.data.data.rows
          // setTimeout(() => {
          //   this.initList()
          // }, 500);
          if(this.timer){
            clearTimeout(this.timer);
          }

        })
      }

    },
  },
  mounted() {
    this.loadList()

  },
  watch: {
    // 监听路由参数的变化
    $route(to, from) {
      console.log(to, from)
      this.loadList()
    }
  },
}
</script>

<style scoped>
  .newListPage{
    vertical-align: top;
    width: calc(50% - 21px);
    box-sizing: border-box;
    margin-right: 20px;
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 30px;

  }
  .newList_box_header{
    background: #F7F7F7;
    line-height: 50px;
    height: 50px;
    padding: 0 10px;
    box-sizing: border-box;
    position: relative;
    z-index: 1000;
  }
  .newList_box_header>div{
    cursor: pointer ;
  }
  .List_nav{
    display: inline-block;
    padding: 0px 10px;
    font-size: 18px;
    font-weight:800;
    color: #555;

  }
  .time{
    display: inline-block;
  }
  .active{
    color: #0144aa;
    line-height: 46px;
    border-bottom: 2px solid #0144aa;
  }
  .newList_li > span{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    width: 80%;
    display: inline-block;
    margin-left: 5%;
    margin-right: 4%;
    vertical-align: top;

  }

  .newList_li{
    font-size: 15px;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #f5f5f5;
  }
  .newList_li:before{
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #0144aa;
    position: relative;
    right: -15px;
    top:-2.5px
  }
  .newList_box{
    max-height: 255px;
    overflow: hidden;
  }
  .newList_box_main{

  }
</style>