<template>
    <div class="footer_box">
        <div class="container">
            <p>地址: 杭州市拱墅区祥符街道中交财富大厦2号楼15楼1501 电话: 057185171361 E-mail: cacaca@zjsca.org.cn</p>
          <p>备案号: 浙ICP备11032166号 <img src="../assets/images/img.png" alt=""> 浙公网安备 33010302003296号</p>
          <p>准到提供技术支持</p>
        </div>
    </div>
</template>

<script>
export default {
  name: "footerTab"
}
</script>

<style scoped>
    .footer_box{
      background: #014099;
      padding: 20px 0;
      color: #ffffff;
      text-align: center;
      line-height: 30px;
      font-size: 16px;
    }

</style>