import axios from 'axios' 


import Tool from './Tool'


const API_URL = 'https://console.zhundaoyun.com/';
/* eslint-disable */
const core = {
    ajax: function (url, type) {
        var token = Tool.getCookie('token');

        return function (data, parm) {
            parm = parm || '';

            if (type == 'post') {
                return axios.post(url + parm, JSON.stringify(data),
                    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            } else {
                return axios.get(url  + parm)
            }
        }
    },

    // token json 提交
    ajax2: function (url, type) {
        var token = Tool.getCookie('token');

        return function (data, parm) {
            parm = parm || '';

            if (type == 'post') {
                return axios.post(url + '?token=' + token + parm, JSON.stringify(data),
                    { headers: { 'Content-Type': 'application/json' } })
            } else {
                return axios.get(url + '?token=' + token + parm)
            }
        }
    },

    ajax3: function (url, type) {
        var token = Tool.getCookie('token');

        return function (data, parm) {
            parm = parm || '';

            if (type == 'post') {
                return axios.post(url + '?token=' + token + parm, JSON.stringify(data),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        responseType: 'blob'
                    })
            } else {
                return axios.get(url + '?token=' + token + parm)
            }
        }
    },
    ajax4: function (url, type) {
        var token = Tool.getCookie('token');

        return function (data, parm) {
            parm = parm || '';

            if (type == 'post') {
                return axios.post(url + '?token=' + token + parm, JSON.stringify(data),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        responseType: 'blob'
                    })
            } else {
                return axios.get(url +'?'+ parm,
                    {
                        headers: { 'token': token },
                    })
            }
        }
    },
}
const api = {
    client: {
        getAppSetting: core.ajax(API_URL + 'api/client/getAppSetting', 'get'),
        web:{
            getArticleSort: core.ajax(API_URL + 'api/client/web/getArticleSort', 'get'),
            getArticleList: core.ajax(API_URL + 'api/client/web/getArticleList', 'get'),
            getArticle: core.ajax(API_URL + 'api/client/web/getArticle', 'get'),
            getArticleSortList: core.ajax(API_URL + 'api/client/web/getArticleSortList', 'get'),
            getWebConfig:core.ajax(API_URL + 'api/client/web/getWebConfig', 'get'),

        }
    },
}



export default api;




