<template>
  <div id="app">
    <keep-alive :include="cached">
      <router-view :key="$route.fullPath"></router-view>
    </keep-alive>
  </div>
</template>
<script>
import $ from 'jquery'
export default {
  created() {
    if (window.innerWidth < 1240) {
      $('body').css('zoom', window.innerWidth / 1240)
    }
  },
  computed: {
    cached() {
      return [this.$route.path]
    }
  }
}

</script>
<style>


</style>
<style>
body,html{
  padding: 0;
  margin: 0;
}
.container{
  width: 1200px;
   min-width:1200px;
  margin:0 auto
}
</style>
