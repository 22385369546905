import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Home/HomeView.vue";
import ArticleList from "../views/Home/ArticleList.vue";
import ArticleDetail from "../views/Home/ArticleDetail.vue";
import ArticleDetail2 from "../views/Home/ArticleDetail2.vue";
import Search from "../views/Home/ArticleListSearch.vue";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/ArticleList/:id",
    name: "ArticleList",
    component: ArticleList,
  },
  {
    path: "/Search/:name",
    name: "Search",
    component: Search,
  },
  {
    path: "/ArticleDetail/:id",
    name: "ArticleDetail",
    component: ArticleDetail,
  },
  {
    path: "/ArticleDetail2/:id",
    name: "ArticleDetail2",
    component: ArticleDetail2,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
